import {Media} from "../../ts/models/Media";

export class Company {
    public id: string;
    public name: string;
    public address: string;
    public zipcode: string;
    public city: string;
    public tel: string;
    public text_quitclaim_top: string;
    public text_quitclaim_bottom: string;
    public primary_color: string;
    public secondary_color: string;
    public text_color: string;
    public background_color: string;
    public logo: Media;
    public login_bg: Media;
    public default_bg: Media;
    public favicon: Media;
    public terms: any;
    public quitclaim: any;
    public active: boolean;
    public max_storage: number;
    public max_users: number;

}
